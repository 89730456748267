import React, { useState, useEffect } from 'react';
import ProductList from '../components/ProductList';
import Footer from '../components/Footer';
import Pagination from '../components/Pagination';
import Navbar from '../components/Navbar';
import FilterSidebar from '../components/FilterSideBar';
import heroImage from '../images/unsplash_xXJ6utyoSw0.png';
import im from '../images/WhatsApp Image 2024-03-13 at 1.27.35 AM.jpeg';
import '../styles/StorePage.css';

export type Product = {
  id: number;
  title: string;
  price: number;
  imageUrl: string;
  images: string[];
  category: string;
  color: string;
  size: string;
};

type Filters = {
  category: string[];
  color: string[];
  size: string[];
  price: string[];
};
const productsPerPage = 12;
const totalProducts = 1000; // Replace with your actual total products count products count
const ProductsPage = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(12); // Number of products you want per page
  const [filters, setFilters] = useState<Filters>({
    category: [],
    color: [],
    size: [],
    price: [],
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Fetch your products from an API or define them here
    const fetchProducts = async () => {
      // Example: fetching from an API
      // const response = await fetch('your-api-url');
      // const data = await response.json();
      // setProducts(data);

      // Static data example
      const staticProducts: Product[] = [];

      for (let i = 0; i < 100; i++) {
        staticProducts.push({
          id: i,
          title: `product${i}`,
          price: 100,
          imageUrl: im,
          images: [],
          category: 'Men',
          color: 'Black',
          size: 'M',
        });
      }
      setProducts(staticProducts);
    };

    fetchProducts();
  }, []);

  const handleFilterChange = (filterType: keyof Filters, value: string) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      if (newFilters[filterType].includes(value)) {
        newFilters[filterType] = newFilters[filterType].filter(
          (item) => item !== value
        );
      } else {
        newFilters[filterType].push(value);
      }
      return newFilters;
    });
  };

  const handleResetFilters = () => {
    setFilters({ category: [], color: [], size: [], price: [] });
  };

  const filteredProducts = products.filter((product) => {
    const { category, color, size, price } = filters;
    return (
      (category.length > 0 ? category.includes(product.category) : true) &&
      (color.length > 0 ? color.includes(product.color) : true) &&
      (size.length > 0 ? size.includes(product.size) : true) &&
      (price.length > 0
        ? price.some((range) => {
            const [min, max] = range.split('-').map(Number);
            return product.price >= min && (!max || product.price <= max);
          })
        : true)
    );
  });

  // Get current products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div>
      <Navbar />
      <div className={'store-page-container'}>
        <div className="breadcrumb-container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item"><a href="#">Vancouver</a></li>
              <li className="breadcrumb-item"><a href="#">Womenswear</a></li>
              <li className="breadcrumb-item"><a href="#">Clothing</a></li>
              <li className="breadcrumb-item active" aria-current="page">So&So</li>
            </ol>
          </nav>
        </div>

        <div className="products-page-store">
          <div className="products-description-store flex-row">
            <div className="text-section-store">
              <h1 className="title-header-store">So&So</h1>
              <p className="title-description">
                Browse elegant layers and everyday pieces to add to your warm
                wardrobe with our women's cardigans and jumpers. Discover the
                latest prints and colours, mixed with timeless knits and neutral
                wardrobe basics that will last season after season. Choose from
                cosy long cardigans to wear around the house as well as chic
                cashmere jumpers in a variety of tones, or go for one of our
                stylish boleros. Opt for chunky knits or finer weaves, select
                from throw- on oversized pieces or cropped cardigans. Explore
                the full range or check out our sweater vests.
              </p>
            </div>
            <div className="img-store">
              <img src={heroImage} width="100%" height="320" />
            </div>
          </div>

          <div className="products-content">
            <div className="products-list-container">
              <div className='number-sort-container'>
                {!isMobile && <h3 className='number-products'>100 Products</h3>}
                <div className='products-sort-store'>
                  <select className='Form-control'>
                    <option>Featured Products</option>
                    <option>Price: High to Low</option>
                    <option>Price: Low to High</option>
                  </select>
                </div>
              </div>
              <ProductList products={currentProducts} />
            </div>
          </div>
          <Pagination
            totalItems={filteredProducts.length}
            itemsPerPage={productsPerPage}
            currentPage={currentPage}
            onPageChange={paginate}
          />
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default ProductsPage;
