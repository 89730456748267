import React, { useRef } from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/FeaturedProducts.css';

const featuredProducts = [
  {
    id: 1,
    image: require('../images/tb-1.jpg'),
    title: 'Product 1',
    price: '$49.99',
    link: '/product/1',
  },
  {
    id: 2,
    image: require('../images/tb-2.jpg'),
    title: 'Product 2',
    price: '$69.99',
    link: '/product/2',
  },
  {
    id: 3,
    image: require('../images/tb-3.jpg'),
    title: 'Product 3',
    price: '$89.99',
    link: '/product/3',
  },
  {
    id: 4,
    image: require('../images/tb-4.jpg'),
    title: 'Product 4',
    price: '$99.99',
    link: '/product/4',
  },
  {
    id: 5,
    image: require('../images/tb-5.jpg'),
    title: 'Product 5',
    price: '$49.99',
    link: '/product/1',
  },
  {
    id: 6,
    image: require('../images/tb-6.jpg'),
    title: 'Product 6',
    price: '$69.99',
    link: '/product/2',
  },
  {
    id: 7,
    image: require('../images/tb-7.jpg'),
    title: 'Product 7',
    price: '$89.99',
    link: '/product/3',
  },
  {
    id: 8,
    image: require('../images/WhatsApp Image 2024-03-13 at 1.27.35 AM.jpeg'),
    title: 'Product 8',
    price: '$99.99',
    link: '/product/4',
  },
];

const FeaturedProducts: React.FC = () => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (carouselRef.current) {
      const width =
        carouselRef.current.querySelector('.product-col')?.clientWidth || 0;
      carouselRef.current.scrollBy({ left: -width, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      const width =
        carouselRef.current.querySelector('.product-col')?.clientWidth || 0;
      const maxScrollLeft =
        carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
      const newScrollLeft = Math.min(
        carouselRef.current.scrollLeft + width,
        maxScrollLeft
      );
      carouselRef.current.scrollTo({ left: newScrollLeft, behavior: 'smooth' });
    }
  };

  return (
    <section className="featured-products">
      <Container fluid>
        <div className="carousel-container">
          <button className="carousel-control left" onClick={scrollLeft}>
            &#10094;
          </button>
          <div className="carousel" ref={carouselRef}>
            <Row className="flex-nowrap">
              {featuredProducts.map((product) => (
                <Col
                  key={product.id}
                  xs={3}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={1.1}
                  className="product-col"
                >
                  <div key={product.id} className="featured-product-card">
                    <a href={product.link}>
                      <img
                        src={product.image}
                        alt={product.title}
                        className="featured-product-image"
                      />
                    </a>
                    <div className="featured-product-info">
                      <h3 className="featured-product-title">
                        SO&SO {product.title}
                      </h3>
                      <p className="featured-product-price">{product.price}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <button className="carousel-control right" onClick={scrollRight}>
            &#10095;
          </button>
        </div>
      </Container>
    </section>
  );
};

export default FeaturedProducts;
