import { useState, useEffect } from 'react';
import { CartItem } from '../model/CartItem';
import Cookies from 'js-cookie';

export const useCart = (): [CartItem[], (items: CartItem[]) => void] => {
  // Initialize state with session storage or empty array
  const [cartItems, setCartItems] = useState<CartItem[]>(() => {
    const cartData = localStorage.getItem('cart');
    return cartData ? JSON.parse(cartData) : [];
  });

  // Update session storage when cartItems changes
  useEffect(() => {
    setCartItems(cartItems);
    localStorage.setItem('cart', JSON.stringify(cartItems));
  }, [cartItems]);

  return [cartItems, setCartItems];
};

export function useCurrentTotal(initialCount = 0) {
  const [currentTotal, setCurrentTotal] = useState<number>(initialCount);

  const updateCurrentTotal = (value: number) => setCurrentTotal(value);

  return [currentTotal, updateCurrentTotal];
}
