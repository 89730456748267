import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  ListGroup,
  Form,
  Table,
  Button,
} from 'react-bootstrap';
import { FaSave, FaTimes, FaEdit } from 'react-icons/fa';
import '../../styles/UserPage.css';
import Navbar from '../../components/Navbar';
import { Link } from 'react-router-dom'; // Import Link for navigation

interface UserDetails {
  username: string;
  password: string;
  address: string;
  creditCard: {
    number: string;
    holder: string;
    expiryDate: string;
    cvv: string;
  };
}

const UserPage: React.FC = () => {
  const [userDetails, setUserDetails] = useState<UserDetails>({
    username: 'john_doe',
    password: '******',
    address: '1234 Main St, Anytown, Canada, A1B 2C3',
    creditCard: {
      number: '**** **** **** 1234',
      holder: 'John Doe',
      expiryDate: '12/24',
      cvv: '',
    },
  });
  const [editingField, setEditingField] = useState<string | null>(null);
  const [tempDetails, setTempDetails] = useState<any>({});
  const [orders] = useState([
    { id: 1, status: 'Delivered', date: '2023-07-01' },
    { id: 2, status: 'Processing', date: '2023-07-15' },
    { id: 3, status: 'Shipped', date: '2023-07-20' },
  ]);

  const handleEdit = (field: string, value: any) => {
    setEditingField(field);
    if (field === 'address') {
      const [street, city, country, postalCode] = value
        .split(',')
        .map((part: string) => part.trim());
      setTempDetails({ street, city, country, postalCode });
    } else if (field === 'creditCard') {
      setTempDetails({ ...value });
    } else {
      setTempDetails({ [field]: value });
    }
  };

  const handleSave = (field: string) => {
    if (field === 'address') {
      const { street, city, country, postalCode } = tempDetails;
      setUserDetails({
        ...userDetails,
        [field]: `${street}, ${city}, ${country}, ${postalCode}`,
      });
    } else {
      setUserDetails({
        ...userDetails,
        [field]: tempDetails[field] || tempDetails,
      });
    }
    setEditingField(null);
    setTempDetails({});
  };

  const handleCancel = () => {
    setEditingField(null);
    setTempDetails({});
  };

  const handleTempChange = (field: string, value: any) => {
    setTempDetails({ ...tempDetails, [field]: value });
  };

  return (
    <>
      <Navbar />
      <Container className="user-page-container">
        <Tab.Container defaultActiveKey="user">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column custom-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="user">User</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="orders">My Orders</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="user">
                  <ListGroup variant="flush">
                    {Object.entries(userDetails).map(([field, value]) => (
                      <ListGroup.Item key={field} className="user-detail-item">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="user-detail-label">
                            <strong>
                              {field.charAt(0).toUpperCase() + field.slice(1)}:
                            </strong>
                          </div>
                          {editingField !== field && (
                            <Button
                              variant="link"
                              onClick={() => handleEdit(field, value)}
                            >
                              <FaEdit />
                            </Button>
                          )}
                        </div>
                        {editingField === field ? (
                          <>
                            {field === 'password' ? (
                              <>
                                <Form.Control
                                  type="password"
                                  placeholder="Old Password"
                                  value={tempDetails.oldPassword || ''}
                                  onChange={(e) =>
                                    handleTempChange(
                                      'oldPassword',
                                      e.target.value
                                    )
                                  }
                                  className="my-2"
                                />
                                <Form.Control
                                  type="password"
                                  placeholder="New Password"
                                  value={tempDetails.newPassword || ''}
                                  onChange={(e) =>
                                    handleTempChange(
                                      'newPassword',
                                      e.target.value
                                    )
                                  }
                                  className="my-2"
                                />
                                <Form.Control
                                  type="password"
                                  placeholder="Confirm New Password"
                                  value={tempDetails.confirmNewPassword || ''}
                                  onChange={(e) =>
                                    handleTempChange(
                                      'confirmNewPassword',
                                      e.target.value
                                    )
                                  }
                                  className="my-2"
                                />
                              </>
                            ) : field === 'address' ? (
                              <>
                                <Form.Group>
                                  <Form.Label>
                                    Street Number and Name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Street Number and Name"
                                    value={tempDetails.street || ''}
                                    onChange={(e) =>
                                      handleTempChange('street', e.target.value)
                                    }
                                    className="my-2"
                                  />
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>City</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="City"
                                    value={tempDetails.city || ''}
                                    onChange={(e) =>
                                      handleTempChange('city', e.target.value)
                                    }
                                    className="my-2"
                                  />
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>Country</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Country"
                                    value={tempDetails.country || 'Canada'}
                                    onChange={(e) =>
                                      handleTempChange(
                                        'country',
                                        e.target.value
                                      )
                                    }
                                    className="my-2"
                                  />
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>Postal Code</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Postal Code"
                                    value={tempDetails.postalCode || ''}
                                    onChange={(e) =>
                                      handleTempChange(
                                        'postalCode',
                                        e.target.value
                                      )
                                    }
                                    className="my-2"
                                  />
                                </Form.Group>
                              </>
                            ) : field === 'creditCard' ? (
                              <>
                                <Form.Group>
                                  <Form.Label>Credit Card Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Credit Card Number"
                                    value={tempDetails.number || value.number}
                                    onChange={(e) =>
                                      handleTempChange('number', e.target.value)
                                    }
                                    className="my-2"
                                  />
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>
                                    Credit Card Holder Name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Credit Card Holder Name"
                                    value={tempDetails.holder || value.holder}
                                    onChange={(e) =>
                                      handleTempChange('holder', e.target.value)
                                    }
                                    className="my-2"
                                  />
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>Expiry Date</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Expiry Date"
                                    value={
                                      tempDetails.expiryDate || value.expiryDate
                                    }
                                    onChange={(e) =>
                                      handleTempChange(
                                        'expiryDate',
                                        e.target.value
                                      )
                                    }
                                    className="my-2"
                                  />
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>CVV</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="CVV"
                                    value={tempDetails.cvv || ''}
                                    onChange={(e) =>
                                      handleTempChange('cvv', e.target.value)
                                    }
                                    className="my-2"
                                  />
                                </Form.Group>
                              </>
                            ) : (
                              <Form.Control
                                type="text"
                                value={tempDetails[field] || value}
                                onChange={(e) =>
                                  handleTempChange(field, e.target.value)
                                }
                                className="my-2"
                              />
                            )}
                            <div className="d-flex justify-content-start">
                              <Button
                                variant="link"
                                onClick={() => handleSave(field)}
                              >
                                <FaSave />
                              </Button>
                              <Button variant="link" onClick={handleCancel}>
                                <FaTimes />
                              </Button>
                            </div>
                          </>
                        ) : (
                          <span className="user-detail-value">
                            {typeof value === 'string'
                              ? value
                              : field === 'creditCard'
                              ? value.number
                              : ''}
                          </span>
                        )}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Tab.Pane>
                <Tab.Pane eventKey="orders">
                  <h2>My Orders</h2>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Status</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((order) => (
                        <tr key={order.id}>
                          <td>{order.id}</td>
                          <td>{order.status}</td>
                          <td>{order.date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
};

export default UserPage;
