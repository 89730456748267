import React from 'react';
import '../styles/ProductionCard.css';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
type ProductCardProps = {
  id: number;
  title: string;
  price: number;
  imageUrl: string;
};

const ProductCard: React.FC<ProductCardProps> = ({
  id,
  title,
  price,
  imageUrl,
}) => {
  const navigate = useNavigate();

  const navigateToProductPage = () => {
    navigate(`/product/${id}`);
  };
  return (
    <Card className="product-card">
      <a href={`/product/${id}`}>
        <Card.Img variant="top" src={imageUrl} />
      </a>

      <Card.Body>
        <div className="product-card-body">
          <div className="title-price flex-column">
            <Card.Title>SO&SO {title}</Card.Title>
            <p className="brand-price">${price}</p>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
