import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import '../styles/About.css';


const About = () => {
  return (
    <div>
        <Navbar />
        <h1 className={'title'}>ABOUT US </h1>
        <p className='description'>
        Mueda is a new fashion marketplace that helps shoppers discover 
        independent brands from major cities around the world. By allowing 
        customers to select a city and explore local boutiques, Mueda offers 
        a unique shopping experience that feels like traveling to fashion hubs. 
        Unlike traditional platforms, Mueda features only smaller, high-quality brands, 
        helping emerging designers gain international exposure. With its city-select model 
        and transparent commission structure, Mueda makes it easy for these brands to reach 
        North American customers, offering exclusive, well-crafted pieces that reflect personal 
        style and support local talent.
        </p>
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header className='titleA'>DELIVERY POLICY</Accordion.Header>
                <Accordion.Body>
                    <p className='descriptionA'>We are committed to providing you with a 
                       seamless shopping experience. Below, you will find detailed information regarding 
                       our shipping and delivery policies.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Shipping Destinations: </h3> We currently offer shipping services to the USA and Canada.
                    </p>
                    <h3 className='boldedWord' >Free Shipping:</h3>
                    <ul className='bullets'>
                        <li><div className='bcountries'>USA:</div> Enjoy complimentary shipping on orders over $200 USD.</li>
                        <li><div className='bcountries'>Canada:</div> Enjoy complimentary shipping on orders over $300 CAD.</li>
                        <li>For orders below these thresholds, a shipping fee of $10 per 
                            brand will apply. For instance, if you purchase items from two 
                            different brands, a $10 shipping fee per brand will be charged, 
                            totaling $20.
                        </li>
                    </ul>
                    
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Order Confirmation:</h3> Upon placing an order, you will receive an order confirmation email 
                        summarizing your purchase details
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Order Processing Time: </h3>Order processing times vary by brand and depend on the individual brand's processing speed. Upon processing, you will receive a confirmation email with a tracking link.
                    </p>
                    
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Order Tracking: </h3> Once your order has been processed, you will receive an email containing a tracking link, allowing you to monitor your shipment's progress.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Multiple Brand Orders: </h3>  If your order includes items from multiple brands, each brand will handle its own shipping. Consequently, you may receive separate packages at different times.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Order Cancellations: </h3>  If you need to cancel your order, please contact our customer support team at customer-support@mueda.co as soon as possible. If the order has already been processed and cannot be canceled in time, you can initiate a return as outlined in our return policy.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Return Policy: </h3> For comprehensive details on returns and exchanges, please refer to our [Return Policy](link to your return policy). Briefly, we offer free returns within 14 days of delivery, and you can print a return shipping label from our website.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Expedited Shipping: </h3>Expedited shipping options are available for an additional fee. The costs and delivery times for expedited shipping will be calculated at checkout.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Shipping Outside of North America:</h3> At this time, we do not offer shipping services outside the USA and Canada.
                    </p>
                    <p className='disclaimer'>
                    If you have any questions or require further assistance, please do not hesitate to contact our customer service team at <br /> <mark>customer-service@mueda.co</mark>
                    </p>
                    
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>RETURN POLICY</Accordion.Header>
                <Accordion.Body>
                    <p className='descriptionA'>At Mueda, we strive to make your shopping experience as seamless as possible. 
                        To ensure clarity and transparency, here are additional details regarding our refund and return policy:
                    </p>
                    <h3 className='boldedWord' >Eligibility for Returns:</h3>
                    <ul className='bullets'>
                        <li>Items must be returned unused, unworn, and in their original packaging with all tags attached.</li>
                        <li>Returns must be initiated within 14 days from the date of delivery.</li>
                    </ul>
                    <h3 className='boldedWord' >How to Initiate a Return:</h3>
                    <ul className='bullets'>
                        <li>Contact our customer service team at <mark>customer-support@mueda.co</mark> to request a return authorization. Please provide your order number and details of the item(s) you wish to return.</li>
                        <li>Once your return request is approved, you will receive an email with instructions on how to proceed, including a shipping label for your return.</li>
                    </ul>
                    <h3 className='boldedWord' >Return Shipping:</h3>
                    <ul className='bullets'>
                        <li>We offer free return shipping for all eligible returns within the continental United States and Canada.</li>
                        <li>Customers outside the continental United States or Canada are responsible for the cost of return shipping.</li>
                    </ul>
                    <h3 className='boldedWord' >Return Process:</h3>
                    <ul className='bullets'>
                        <li>Package your item(s) securely, ensuring they are protected during transit.</li>
                        <li>Attach the provided shipping label to the outside of the package. Please make sure to cover or remove any previous shipping labels.</li>
                        <li>Drop off the package at any authorized shipping location or schedule a pickup with the designated carrier.</li>
                    </ul>
                    <h3 className='boldedWord' >Refund Processing:</h3>
                    <ul className='bullets'>
                        <li>Once we receive your returned item(s) and verify their eligibility, we will process your refund within 3 working days.</li>
                        <li>Refunds will be issued to the original method of payment.</li>
                    </ul>
                    <h3 className='boldedWord' >Exchanges:</h3>
                    <ul className='bullets'>
                        <li>We do not offer exchanges for different sizes. If you require a different size, please return the item following our return process and place a new order for the desired size.</li>
                    </ul>
                    <h3 className='boldedWord' >Specific Instructions for Briefs and Swimwear:</h3>
                    <ul className='bullets'>
                        <li>Briefs and swimwear must be tried on over underwear. For hygiene reasons, items must be returned in their original condition with the strip intact and not removed.</li>
                    </ul>
                    <h3 className='boldedWord' >Conditions of Return:</h3>
                    <ul className='bullets'>
                        <li>We reserve the right to reject returns that do not meet our eligibility criteria or show signs of wear or damage.</li>
                        <li>If an item is deemed ineligible for a refund, it will be returned to the buyer at their expense.</li>
                    </ul>
                    <h3 className='boldedWord' >Reporting Faulty Goods:</h3>
                    <ul className='bullets'>
                        <li>If you receive a faulty item, please inform us within 14 days of receiving your package.</li>
                        <li>To report a faulty item, please email us at <mark>customer-support@mueda.co</mark> with a photo of the receipt and a clear description of the fault.</li>
                    </ul>
                    <h3 className='boldedWord' >Return Process for Faulty Goods:</h3>
                    <ul className='bullets'>
                        <li>Upon receiving your report, we will arrange for the free return of the faulty item.</li>
                        <li>You can choose to receive a refund for the faulty item or a replacement at no additional cost.</li>
                    </ul>
                    <h3 className='boldedWord' >After 14 Days:</h3>
                    <ul className='bullets'>
                        <li>If you report a fault after 14 days of receiving your package, we will still assist you in resolving the issue.</li>
                        <li>We will contact the brand on your behalf to seek a solution. However, we are unable to offer a repair, replacement, or refund for items owned for more than 6 months.</li>
                    </ul>
                    <h3 className='boldedWord' >Exclusions:</h3>
                    <ul className='bullets'>
                        <li>Items damaged due to normal wear and tear, accident, or misuse will not be considered faulty.</li>
                        <li>Our faulty goods policy does not cover damages resulting from improper care or handling of the item.</li>
                    </ul>
                    <h3 className='boldedWord' >Contact Us:</h3>
                    <p className='disclaimer'>
                    If you have any questions or concerns about our refund and return policy, or if you need assistance with a return, please don't hesitate to contact our customer service team at <mark>customer-support@mueda.co</mark>
                    </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header className='titleA'>TERMS & CONDITIONS</Accordion.Header>
                <Accordion.Body>
                    <p className='descriptionA'>
                        By using our services, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Commission and Fees:</h3> Mueda takes a 20% commission on each sale, which includes a 2.9% + 30 cent Stripe fee and a 17.1% Mueda fee.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Payment to Sellers:</h3> Sellers will receive their revenue bi-weekly. Payments will be processed and transferred to the seller's designated account.

                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Shipping and Free Shipping Threshold:</h3> If a buyer meets the free shipping threshold ($200 USD for the USA and $300 CAD for Canada), the seller is responsible for covering the shipping costs. If the order total is less than $80 CAD or $70 USD, Mueda will reimburse the seller 50% of the shipping fee. To make shipping affordable for customers, if the free shipping threshold hasn't been met, after the buyer spends $10 on shipping, the seller is responsible for covering the rest of the cost.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>White Label Shipping:</h3> Mueda offers Shippo's white label API. The shipping labels printed and attached to packages will have the company name "Mueda" on them.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Returns:</h3> Buyers have the right to return their order within 14 days of delivery. Sellers must reimburse the full amount paid by the buyer, including the cost of the return shipping label. Refunds must be processed as soon as the clothes have been returned and must be completed within 3 days of receipt.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Independent Orders:</h3> Orders from different brands are handled independently and may arrive at different times.

                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Customer Support:</h3> For any issues, including order cancellations, customers can contact our support team at <mark>customer-support@mueda.co</mark>
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Seller Responsibilities:</h3> Sellers are responsible for ensuring the accuracy and completeness of their product listings. This includes providing detailed descriptions, clear images, and accurate pricing. Sellers must maintain adequate stock levels to fulfill orders promptly. If an item is out of stock, it must be promptly updated in the system. Sellers must comply with all applicable laws and regulations regarding the sale of their products, including but not limited to consumer protection, product safety, and intellectual property laws.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Product Standards and Compliance:</h3> All products listed on Mueda must be of high quality and meet all relevant safety standards. Sellers are prohibited from listing counterfeit, infringing, or illegal products. Clear and accurate descriptions and images must be provided for each product.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Order Processing and Shipping:</h3> Sellers must process and ship orders within the specified timeframe. Delays must be promptly communicated to Mueda and the customer. Sellers must use Mueda’s provided shipping labels and comply with packaging guidelines to ensure safe delivery. If applicable, sellers must disclose in their listings that buyers have the option of paying for duties.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Return and Refund Policy:</h3> Returns are accepted within 14 days of delivery. Items must be unused and in their original packaging. Sellers must reimburse the full amount paid by the buyer, including the cost of the return shipping label. Refunds must be processed as soon as the clothes have been returned and must be completed within 3 days of receipt.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Dispute Resolution:</h3> In the event of a dispute between the seller and a buyer or between the seller and Mueda, the parties will first attempt to resolve the dispute informally. If unresolved, mediation or arbitration may be pursued. If applicable, any disputes arising under these terms will be resolved through binding arbitration in accordance with specified arbitration rules.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Prohibited Conduct:</h3> Sellers are prohibited from engaging in false, misleading, or deceptive practices. Sellers must not engage in activities that would harm the reputation of Mueda or other sellers.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Termination of Agreement:</h3> Mueda reserves the right to terminate this agreement at any time for any reason. Sellers may terminate the agreement with a specified notice period. Termination will not affect existing orders, and all outstanding payments will be processed accordingly.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Intellectual Property:</h3> Sellers must respect Mueda’s intellectual property rights, including trademarks, logos, and branding. Any use of Mueda's trademarks must comply with Mueda’s branding guidelines.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Confidentiality:</h3> Sellers must keep any sensitive information received from Mueda confidential and not disclose it to any third party. Sellers may be required to sign a non-disclosure agreement to further protect confidential information.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Liability and Indemnity:</h3> Sellers are liable for their products and any claims arising from their sale or use. Sellers agree to indemnify and hold harmless Mueda from any claims, damages, or expenses arising from their products or conduct.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Free Tier:</h3> The first 20 products posted by a seller are free of charge. After the first 20 products, each additional posting costs 10 cents, regardless of whether the item sells or not. Each post can include up to 6 photos, a detailed description, sizes, colors, and other relevant information.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Miscellaneous:</h3> These terms are governed by the laws of the specified jurisdiction. If any provision of these terms is found to be invalid or unenforceable, the remaining provisions will continue in effect. These terms constitute the entire agreement between Mueda and the seller and supersede all prior agreements and understandings.
                    </p>
                    <p className='disclaimer'>
                        By using Mueda's services, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.
                    </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header className='titleA'>COOKIE POLICY</Accordion.Header>
                <Accordion.Body>
                    <p className='descriptionA'>
                        By using our services, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>What are Cookies?</h3> Cookies are small text files that are stored on your device when you visit a website. They help websites recognize your device and remember your preferences and actions over time.
                    </p>
                    <h3 className='boldedWord' >Types of Cookies We Use:</h3>
                    <ul className='bullets'>
                        <li>Essential Cookies: These cookies are necessary for the functioning of our website and cannot be disabled in our systems. They enable core functionality such as security, network management, and accessibility.</li>
                        <li>Analytics Cookies: We use analytics cookies to collect information about how visitors interact with our website, such as which pages they visit and how long they spend on each page. This helps us improve the performance and usability of our website.</li>
                        <li>Advertising Cookies: We may use advertising cookies to deliver targeted advertisements to you based on your interests and browsing behavior. These cookies may track your visits to other websites as well.</li>
                    </ul>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Your Cookie Choices:</h3>You can control and manage cookies in your browser settings. Most web browsers allow you to accept or reject cookies, delete existing cookies, and set preferences for certain websites. Please note that if you choose to disable cookies, some features of our website may not function properly.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Third-Party Cookies:</h3>We may also use third-party cookies provided by service providers such as Google Analytics and advertising networks. These cookies may collect information about your online activities across websites and over time to provide targeted advertising.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Updates to This Policy:</h3>We may update this Cookie Policy from time to time to reflect changes in our use of cookies or legal requirements. We will notify you of any material changes by posting the updated policy on our website.
                    </p>
                    <p className='descriptionA'>
                        <h3 className='boldedWord'>Contact Us:</h3>If you have any questions or concerns about this Cookie Policy or our use of cookies, please contact us at <mark>customer-support@mueda.co</mark>
                    </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header className='titleA' id='p-policy-0'>PRIVACY POLICY</Accordion.Header>
                <Accordion.Body>

                </Accordion.Body>
            </Accordion.Item>





        </Accordion>
        
 




        <Footer />
    </div>   
  );
};

export default About



