import React, { useRef } from 'react';
import { Card, Button, Container, Row, Col, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/ForYouProducts.css';

const forYouProducts = [
  {
    id: 1,
    image: require('../images/tb-1.jpg'),
    title: 'Product 1',
    price: '$49.99',
    link: '/product/1',
  },
  {
    id: 2,
    image: require('../images/tb-2.jpg'),
    title: 'Product 2',
    price: '$69.99',
    link: '/product/2',
  },
  {
    id: 3,
    image: require('../images/tb-3.jpg'),
    title: 'Product 3',
    price: '$89.99',
    link: '/product/3',
  },
  {
    id: 4,
    image: require('../images/tb-4.jpg'),
    title: 'Product 4',
    price: '$99.99',
    link: '/product/4',
  },
];

interface ForYouProductsProps {
  title: string; // Add title prop
}

const ForYouProducts: React.FC<ForYouProductsProps> = ({ title }) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  return (
    <section className="for-you-products">
      <h2 className="for-you-products-section-title">{title}</h2>

      <div className="for-you-products-grid">
        {forYouProducts.map((product) => (
          <div key={product.id} className="for-you-product-card">
            <a href={product.link}>
              <img
                src={product.image}
                alt={product.title}
                className="for-you-product-image"
              />
            </a>
            <div className="for-you-product-info">
              <h3 className="for-you-product-title">SO&SO {product.title}</h3>
              <p className="for-you-product-price">{product.price}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ForYouProducts;
