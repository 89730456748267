import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import '../styles/HeroCarousel.css'; // Make sure to create this CSS file for styling
import heroImage from '../images/hero_section_resized.jpg';
import hero2 from '../images/fashion_hero_section_new.jpg';
import hero3 from '../images/fashion_hero_section_less_height.jpg';

const heroData = [
  {
    id: 1,
    image: require('../images/homeImage.jpg'),
    title: 'Fall Coats and Jackets',
    description:
      'Outwear goes sleek. From supple leather bombers to tailored wool coats.',
    link: 'https://www.google.com',
  },
  {
    id: 2,
    image: require('../images/homeImage3.png'),
    title: 'Fall Denim',
    description:
      'The material of the season. From jeans with laidback <br /> attitude to classic denim jackets.',
    link: 'https://www.twitter.com',
  },
];
const HeroCarousel: React.FC = () => {
  return (
    <section id="home" className="hero-block">
      <Carousel>
        {heroData.map((hero) => {
          return (
            <Carousel.Item key={hero.id}>
              <img
                className="d-block w-100"
                src={hero.image}
                alt={'slide ' + hero.id}
              />
              <Carousel.Caption className='centerCap'>
                <h2>{hero.title}</h2>
                
                <p dangerouslySetInnerHTML={{ __html: hero.description }}></p>
                <div className="button-group">
                  <a className="btn btn-primary mensButton" href="/products">
                    Mens <i className="fas fa-chevron-right"></i>
                  </a>
                  <a className="btn btn-primary womensButton" href="/products">
                    Womens <i className="fas fa-chevron-right"></i>
                  </a>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </section>
  );
};

export default HeroCarousel;
