// ProductPage.tsx
import React, { useState } from 'react';
// Assuming a predefined Product type and fetchProductById function.
// import { Product, fetchProductById } from './ProductService';
import { ListBox, ListBoxItem } from 'react-aria-components';
import { Product } from './ProductsPage';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Dropdown,
  Accordion,
  Card,
} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import Footer from '../components/Footer';
import productsPage from './ProductsPage';
import hero2 from '../images/fashion_hero_section_new.jpg';
import im from '../images/WhatsApp Image 2024-03-13 at 1.27.35 AM.jpeg';
import im2 from '../images/thom-browne-navy-hector-sweater (2).jpg';
import im3 from '../images/thom-browne-navy-hector-sweater.jpg';
import im4 from '../images/thom-browne-navy-hector-sweater4.jpg';

import './../styles/ProductPage.css';
import Navbar from '../components/Navbar';
import { useCart } from '../hooks/Carthooks'; // Make sure to import your CSS
import ForYouProducts from '../components/ForYouProducts';
import ProductReviews from '../components/ProductReviews';

const ProductPage: React.FC = () => {
  const { id } = useParams();
  const fetchProductById = () => {
    // @TODO fetch from API

    return {
      id: Number(id),
      title: `SO&SO ${id}`,
      price: 100,
      imageUrl: im,
      images: [im, im2, im3, im4],
      category: 'Men',
      color: 'Black',
      size: 'M',
    };
  };
  const navigate = useNavigate();
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<Product | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentSize, setCurrentSize] = useState('');
  const [currentColor, setCurrentColor] = useState('');
  const [cartItems, setCartItems] = useCart();
  const [isAdded, setIsAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const nextImage = () => {
    var length = product?.images.length;

    // @ts-ignore

    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % length);
  };

  const prevImage = () => {
    var length = product?.images.length;

    // @ts-ignore
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + length) % length);
  };

  React.useEffect(() => {
    // Simulate fetching product by ID
    const productDetails = fetchProductById();
    setProduct(productDetails);
  }, [productId]);

  if (!product) return <div>Loading...</div>;

  const handleImageChange = (index: number) => {
    setCurrentImageIndex(index);
  };

  const handleAddToCart = () => {
    if (isAdded) {
      navigate(`/shopping-bag`);
      return;
    }
    const item = {
      id: product.id,
      title: product.title,
      price: product.price,
      imageUrl: im,
      size: currentSize,
      color: currentColor,
      quantity: 1,
    };
    const index = cartItems.findIndex(
      (item) =>
        item.id == product.id &&
        item.size == currentSize &&
        item.color == currentColor
    );
    // does not exist
    if (index < 0) {
      setCartItems([...cartItems, item]);
    } else {
      cartItems[index].quantity = cartItems[index].quantity + 1;
      setCartItems([...cartItems]);
    }
    setIsLoading(true);
    // Simulate an API call with a timeout
    setTimeout(() => {
      setIsAdded(true);
      setIsLoading(false);
    }, 500); // Simulate a network request time
  };

  return (
    <>
      <Navbar />
      <div className="product-page-container">
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, display: 'flex' }}>
            {/*images on the left*/}
            <div className="image-options">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={'Thumbnail'}
                  style={{ width: 50, height: 'auto', cursor: 'pointer' }}
                  onClick={() => handleImageChange(index)}
                />
              ))}
            </div>

            <div className="image-container">
              {product.images.length > 1 && (
                <>
                  <div className="left-arrow" onClick={prevImage}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                  <div className="right-arrow" onClick={nextImage}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </>
              )}
              <img
                src={product.images[currentImageIndex]}
                alt={'sdf'}
                className="product-image"
              />
            </div>
          </div>

          {/*right part*/}
          <div className={'product-details'}>
            <p className="text-muted">
              {product.title} DOG SWEATER
            </p>

            <h3 className='productPrice'>${product.price} USD</h3>
            
            <Dropdown className="w-100">
              <Dropdown.Toggle
                variant="outline-secondary"
                className="custom-dropdown"
              >
                {currentSize || 'size'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className="custom-dropdown"
                  onClick={() => setCurrentSize('XS')}
                >
                  XS
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setCurrentSize('S')}>
                  S
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            
            <Dropdown className="my-3">
              <Dropdown.Toggle
                variant="outline-secondary"
                className="custom-dropdown"
              >
                {currentColor || 'color'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setCurrentColor('Blue')}>
                  Blue
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setCurrentColor('Red')}>
                  Red
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <p>
              Knit stretch virgin wool sweater. Intarsia graphic throughout.
            </p>
            <ul>
              <li>Rib knit crewneck, hem and cuffs</li>
              <li>Button vent at side seams and cuffs</li>
              <li> Stripes at sleeve</li>
              <li>Tricolor grosgrain flag at back collar</li>
            </ul>
            <p>
              Supplier color: Navy <br />
              99% virgin wool, 1% polyamide. <br />
              Made in Italy.{' '}
            </p>
            <Button variant="dark" className="dark" onClick={handleAddToCart}>
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Loading...</span>
                </>
              ) : isAdded ? (
                'Added to Cart - Go to Checkout'
              ) : (
                'Add to Cart'
              )}
            </Button>
            {/*<Accordion defaultActiveKey="0" className="my-3">*/}
            {/*    <Card>*/}
            {/*        <Accordion.Toggle as={Card.Header} eventKey="0">*/}
            {/*            Description*/}
            {/*        </Accordion.Toggle>*/}
            {/*        <Accordion.Collapse eventKey="0">*/}
            {/*            <Card.Body>{"des"}</Card.Body>*/}
            {/*        </Accordion.Collapse>*/}
            {/*    </Card>*/}
            {/*</Accordion>*/}
          </div>
        </div>

        <ForYouProducts title={'MORE FROM SO&SO..'} />
        <ProductReviews productId={String(product.id)} />
      </div>

      <Footer />
    </>
  );
};

export default ProductPage;
