import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import HeroCarousel from '../components/HeroCarousel';
import FeaturedProducts from '../components/FeaturedProducts';
import '../styles/Home.css';

const Home = () => {
  return (
    <div className="App">
      <Navbar />
      <HeroCarousel />
      <div className={'discover-div'}>
        <h1 className={'title1'}>DISCOVER</h1>

        <h2 className={'title2'}> VANCOUVER'S LOCAL BRANDS</h2>
      </div>

      <FeaturedProducts />

      <section className="explore-section">
        <div className="section-title">
          <h1 className="explore">EXPLORE</h1>
          <h2 className="more">MORE OF VANCOUVER'S LOCAL BRANDS</h2>
        </div>
        <div className="categories">
          <div className="category">WOMENS DENIM</div>
          <div className="category">WOMENS KNITWEAR</div>
          <div className="category">MENS KNITWEAR</div>
          <div className="category">MENS DENIM</div>
        </div>
        <div className="description">
          <p>
          Welcome to Mueda, a fashion destination that proudly promotes independent and local clothing brands on a global scale. At Mueda, we redefine the shopping experience by curating an exclusive selection of clothing from independent and emerging brands across diverse corners of the world. Navigate through our platform and immerse yourself in the creativity of independent designers and local labels, each piece telling a unique story. Mueda allows you to transcend geographical boundaries, enabling you to select cities from all over the world and discover the latest trends from vibrant independent fashion hubs.
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
