import React, { useState, useEffect } from 'react';
import { Rating } from '@mui/material';
import { Star } from '@mui/icons-material';
import '../styles/ProductReviews.css';

interface Review {
  id: string;
  userId: string;
  username: string;
  rating: number;
  sizeFit: number;
  comment: string;
  date: string;
  verified: boolean;
}

interface ProductReviewsProps {
  productId: string;
}

interface NewReview {
  rating: number;
  sizeFit: number;
  comment: string;
}

const SizeFitBar: React.FC<{ sizeFit: number }> = ({ sizeFit }) => {
  return (
    <div className="size-fit-container">
      <span className="size-label">Runs Small</span>
      <div className="size-fit-bar">
        <div 
          className="size-fit-indicator" 
          style={{ left: `${(sizeFit - 1) * 25}%` }}
        />
      </div>
      <span className="size-label">Runs Large</span>
    </div>
  );
};

const SizeFitSelector: React.FC<{ 
  value: number, 
  onChange: (value: number) => void 
}> = ({ value, onChange }) => {
  return (
    <div className="size-fit-input">
      <h4>Fit</h4>
      <div className="size-fit-container">
        <span className="size-label">Runs Small</span>
        <div 
          className="size-fit-bar clickable"
          onClick={(e) => {
            const rect = e.currentTarget.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const percentage = x / rect.width;
            const newValue = 1 + Math.round(percentage * 4);
            onChange(newValue);
          }}
        >
          <div 
            className="size-fit-indicator" 
            style={{ left: `${(value - 1) * 25}%` }}
          />
        </div>
        <span className="size-label">Runs Large</span>
      </div>
    </div>
  );
};

const ProductReviews: React.FC<ProductReviewsProps> = ({ productId }) => {
  const [reviews, setReviews] = useState<Review[]>([
    {
      id: "1",
      userId: "user1",
      username: "John Doe",
      rating: 5,
      sizeFit: 3,
      comment: "Great quality sweater! The fit is perfect and the material is super comfortable. Definitely worth the price.",
      date: "2024-03-15",
      verified: true
    },
    {
      id: "2",
      userId: "user2",
      username: "Sarah Smith",
      rating: 4,
      sizeFit: 2,
      comment: "Love the design but runs slightly large. I would recommend sizing down.",
      date: "2024-03-10",
      verified: true
    },
    {
      id: "3",
      userId: "user3",
      username: "Mike Johnson",
      rating: 5,
      sizeFit: 3,
      comment: "This is my second purchase from SO&SO. Their quality never disappoints!",
      date: "2024-03-05",
      verified: false
    }
  ]);
  const [userCanReview, setUserCanReview] = useState(true);
  const [newReview, setNewReview] = useState<NewReview>({
    rating: 0,
    sizeFit: 3,
    comment: ''
  });

  // Fetch reviews for this product
  useEffect(() => {
    // TODO: Replace with your actual API call
    const fetchReviews = async () => {
      try {
        // const response = await fetch(`/api/products/${productId}/reviews`);
        // const data = await response.json();
        // setReviews(data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, [productId]);

  // Check if user can review (has purchased and hasn't reviewed yet)
  useEffect(() => {
    // TODO: Replace with your actual API call
    const checkUserCanReview = async () => {
      try {
        // const response = await fetch(`/api/products/${productId}/can-review`);
        // const { canReview } = await response.json();
        // setUserCanReview(canReview);
      } catch (error) {
        console.error('Error checking review eligibility:', error);
      }
    };

    checkUserCanReview();
  }, [productId]);

  const handleSubmitReview = async (e: React.FormEvent) => {
    e.preventDefault();
    // TODO: Replace with your actual API call
    try {
      // const response = await fetch(`/api/products/${productId}/reviews`, {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify(newReview)
      // });
      // if (response.ok) {
      //   // Refresh reviews
      //   setNewReview({ rating: 0, comment: '' });
      // }
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };

  return (
    <div className="reviews-section">
      <h2>Customer Reviews</h2>
      
      {userCanReview && (
        <form onSubmit={handleSubmitReview} className="review-form">
          <h3>Write a Review</h3>
          <div className="rating-input">
            <Rating
              value={newReview.rating}
              onChange={(_, value) => setNewReview(prev => ({ ...prev, rating: value || 0 }))}
              precision={1}
              icon={<Star fontSize="large" />}
            />
          </div>
          <SizeFitSelector
            value={newReview.sizeFit}
            onChange={(value) => setNewReview(prev => ({ ...prev, sizeFit: value }))}
          />
          <textarea
            value={newReview.comment}
            onChange={(e) => setNewReview(prev => ({ ...prev, comment: e.target.value }))}
            placeholder="Share your thoughts about this product..."
            required
          />
          <button type="submit" className="submit-review">Submit Review</button>
        </form>
      )}

      <div className="reviews-list">
        {reviews.map((review) => (
          <div key={review.id} className="review-item">
            <div className="review-header">
              <Rating value={review.rating} readOnly />
              <span className="review-username">{review.username}</span>
              {review.verified && <span className="verified-badge">Verified Purchase</span>}
            </div>
            <SizeFitBar sizeFit={review.sizeFit} />
            <p className="review-comment">{review.comment}</p>
            <span className="review-date">{new Date(review.date).toLocaleDateString()}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductReviews; 